<template>
  <div class="modal modal--note">
    <div class="modal__window">
    <div class="modal__title">Add note for {{date | dateFormat}}</div>
      <div class="modal__help">Add things like allergies and other important messages to the chef.</div>
      <div class="modal__field">
        <textarea class="modal__input" @input="handleInput" :value="value"></textarea>
      </div>
      <div class="modal__buttons">
        <div class="modal__cancel" @click="emtpyNote">Empty note</div>
        <div class="modal__save" @click="handleHideModal">Keep note</div>
      </div>
    </div>
  </div>
</template>

<script>

import { isValid, format } from "date-fns";

export default {
  props: ["value", "date"],

  data() {
    return {
      noteInput: ""
    };
  },

  filters: {
    dateFormat(value) {
      return isValid(value) ? format(value, "dd/MM") : "";
    }
  },

  methods: {
    handleInput(e) {
      if (e.target) {
        this.noteInput = e.target.value;
      } else {
        this.noteInput = e;
      }
      this.$emit("input", this.noteInput);
    },

    emtpyNote() {
      this.handleInput("");
      this.handleHideModal();
    },

    handleHideModal() {
      this.$emit("hideModal", false);
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(2, 15, 38, 0.95);
  border-radius: 0;
  opacity: 1;
  transform: scale(1);
  z-index: 200;
  transition: all 1s ease-in-out 1s;
}

.modal__window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 320px;
}
.modal__title {
  font-family: "Graphik";
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
.modal__help {
  color: #fff;
  font-size: 14px;
  margin: 8px 0 0 0;
  line-height: 1.3;
}
.modal__field {
  margin: 32px 0 0 0;
}
.modal__input {
  width: 100%;
  height: 80px;
  background-color: #fff;
  border: 4px solid #fff;
  font-size: 14px;
  line-height: 1.4;
}
.modal__buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}
.modal__cancel {
  margin: 0 32px 0 0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.modal__cancel:hover {
  color: #26c6da;
}
.modal__save {
  padding: 8px 16px;
  border: 2px solid #26c6da;
  background-color: #26c6da;
  font-family: "Graphik";
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.modal__save:hover {
  background-color: transparent;
}
</style>
