<template>
  <div class="feedback"></div>
</template>

<script>
export default {};
</script>

<style>
.feedback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  visibility: visible;
  background-color: #56a86a;
}

.feedback::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "icons";
  font-size: 142px;
  line-height: 1;
  color: #fff;
  content: "\e904";
}
</style>
