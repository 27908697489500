<template>
  <div
    v-if="numberOfWeeksUpdated === 4"
    class="lunch__status lunch__status--done"
  >
    Well done! You don't need to fill out anything until friday.
  </div>
  <div v-else class="lunch__status lunch__status--error">
    You need to fill out the missing days.
  </div>
</template>

<script>
export default {
  props: {
    numberOfWeeksUpdated: {
      type: Number,
      required: true
    }
  }
};
</script>

<style>
.lunch__status {
  margin: 20px 0;
  padding: 16px;
  border: 2px solid #78bba7;
  background-color: #dbf1ea;
  line-height: 1.4;
}
.lunch__status--error {
  border-color: #992253;
  background-color: #e4c2d0;
}
</style>
