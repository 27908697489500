<template>
  <div class="eggs-logo">
    <svg
      class="logo-svg pulse"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      xml:space="preserve"
      width="200px"
      height="200px"
    >
      <defs>
        <polygon
          id="eggs-logo-a"
          points="0 47.094 0 .408 47.585 .408 47.585 47.094"
        ></polygon>
      </defs>
      <g>
        <path
          class="logo-svg-path1"
          d="M99.95,195.89c53.05,0,96.05-43,96.05-96.05c0-53.05-43-96.05-96.05-96.05s-96.05,43-96.05,96.05
                  C3.89,152.89,46.9,195.89,99.95,195.89"
        ></path>
        <path
          class="logo-svg-path2"
          fill="#FFF"
          d="M119.12,136.07h-12.7c1.67,9.11,9.28,14.7,20.46,14.7c10.19,0,18.25-5.67,18.25-15.15
                  c0-7.16-4.41-11.44-12.4-13.33l-9.13-2.1c-2.44-0.53-3.88-1.66-3.88-3.69c0-2.55,2.36-4.06,5.85-4.06c4.04,0,6.16,1.95,7.15,4.51
                  h11.94c-1.29-7.98-7.75-14.4-19.16-14.4c-10.35,0-17.95,6.19-17.95,15.22c0,7.83,5.17,11.37,12.01,12.88l8.98,1.88
                  c2.97,0.68,4.41,1.65,4.41,3.84c0,2.86-2.59,4.44-6.31,4.44C122.85,140.81,120.11,139.23,119.12,136.07 M57.66,95.89h35.96V85.31
                  H69.48v-7.64h22.77V68H69.48v-7.72h24.13v-10.5H57.66V95.89z M101.59,128.14c0-11.81-7.77-20.92-21.07-20.92h-7.88v20.92h9.68
                  v-10.05c2.84,0.04,8.55,2,8.55,10.05c0,6.69-5.4,10.8-13.38,10.8c-7.76,0-13.39-3.88-13.39-10.65c0-3.63,1.42-6.31,4.06-7.93
                  l-5.76-9.99c-5.51,3.35-9.02,9.4-9.02,17.92c0,13.39,9.88,22.48,24.11,22.48C91.7,150.77,101.59,141.68,101.59,128.14
                   M145.13,75.88V68h-20.92v9.68h10.05c-0.04,2.84-2,8.55-10.05,8.55c-6.7,0-10.8-5.4-10.8-13.38c0-7.76,3.88-13.39,10.65-13.39
                  c3.63,0,6.31,1.42,7.93,4.06l9.99-5.76c-3.35-5.51-9.4-9.02-17.92-9.02c-13.39,0-22.48,9.89-22.48,24.11
                  c0,14.22,9.09,24.11,22.63,24.11C136.03,96.94,145.13,89.17,145.13,75.88"
        ></path>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.eggs-logo {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  80% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
