<template>
  <div class="lunch__row">
    <div class="lunch__day">{{ date | dateFormat }}</div>
    <div class="in-office__field">
      <img src="../../assets/in_office.svg" width="16" alt="in office" />
      <input
        type="checkbox"
        class="office-checkbox"
        :checked="inOffice"
        @change="handleInOfficeChange"
      />
    </div>
    <div class="lunch-input-amount" @click="selectAmountInput">
      <img src="../../assets/food plate knife fork.svg" width="16" alt="" />
      <input
        :value="dayAmount"
        type="tel"
        class="lunch__input"
        maxlength="2"
        ref="input"
        @focus="selectInput"
        @input="handleInput"
        :disabled="isDisabled"
      />
    </div>

    <div class="lunch__note" @click="showModal">{{ note }}</div>
  </div>
</template>

<script>
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isBefore from "date-fns/isBefore";
import subHours from "date-fns/subHours";
import getHours from "date-fns/getHours";

export default {
  props: ["date", "dayAmount", "inOffice"],
  data() {
    return {
      lunchInput: "",
      note: "",
      isShowingModal: false,
      isShowingPortal: false
    };
  },

  computed: {
    isDisabled() {
      return (
        isBefore(this.date, new Date()) ||
        isToday(new Date(this.date)) ||
        (isToday(subHours(this.date, 12)) && getHours(new Date()) >= 12)
      );
      // is the cell's date before today, is it today, or is it tomorrow. If tomorrow, is the time past 12pm
    }
  },

  filters: {
    dateFormat(value) {
      return format(value, "EEE dd/MM");
    }
  },
  methods: {
    handleInOfficeChange(e) {
      this.$emit("inOffice", e.target.checked);
    },

    selectInput() {
      this.$refs.input.select();
      this.$refs.input.focus();

      // IOS workaround
      setTimeout(() => {
        this.$refs.input.setSelectionRange(0, 99999);
      }, 0);
    },

    selectAmountInput() {
      this.$refs.input.select();
      this.$refs.input.focus();

      // IOS workaround
      setTimeout(() => {
        this.$refs.input.setSelectionRange(0, 99999);
      }, 0);
    },

    showModal() {
      this.$emit("showModal", this.date);
    },
    handleInput(e) {
      if (!isNaN(e.data)) {
        this.$emit("input", e.target.value);
      }
    }
  }
};
</script>

<style>
.lunch__day {
  flex-basis: 100%;
  text-transform: capitalize;
}

.lunch__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.office-checkbox {
  -webkit-appearance: checkbox;
}

.in-office__field {
  font-size: 18px;
  size: 3rem;
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 6px;
  border: 2px solid #dbdbdb;
  border-radius: 0;
  margin-right: 8px;
}

.in-office__field > img {
  margin-right: 10px;
}

.lunch-input-amount {
  display: flex;
  position: relative;
}

.lunch-input-amount > img {
  position: absolute;
  top: 10px;
  left: 6px;
}

.lunch__row {
  margin: 8px 0;
}
.lunch__row:last-child {
  margin-bottom: 0;
}

.lunch__input {
  position: relative;
  z-index: 1;
  width: 68px;
  padding: 8px 8px 8px 28px;
  border: 2px solid #dbdbdb;
  border-radius: 0;
  background-color: transparent;
  line-height: 1;
  font-size: 18px;
  color: #727272;
}
.lunch__input:hover {
  border-color: #666;
}
.lunch__input:focus {
  border-color: #77c4d8;
}

.lunch__note {
  position: relative;
  width: 48px;
  height: 38px;
  cursor: pointer;
}
.lunch__note::before {
  content: "\e90f";
  position: absolute;
  top: 50%;
  right: -6px;
  z-index: 2;
  font-family: "icons";
  font-size: 16px;
  line-height: 1;
  transform: translate(-50%, -50%);
  color: #dbdbdb;
}
</style>
