import "./styles/fonts.css";
import "./styles/style.css";
import App from "./App.vue";
import PortalVue from "portal-vue";
import Vue from "vue";
import mixins from "./mixins";
import router from "./router";
import store from "./store";
import ImageUploader from "vue-image-upload-resize";

//Disabled service worker for now
//import "./registerServiceWorker";
Vue.use(ImageUploader);

Vue.config.productionTip = false;
Vue.use(PortalVue);

Vue.mixin(mixins);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
