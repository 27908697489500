<template>
  <div class="vacation-container">
    <div class="checkbox">
      <input
        type="checkbox"
        class="vacation-checkbox"
        :checked="onVacation"
        @change="$emit('setOnVacation', $event)"
      />
      <div class="text"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onVacation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
input {
  -webkit-appearance: checkbox;
}

.checkbox {
  display: flex;
}

.vacation-checkbox {
  font-size: 18px;
  size: 3rem;
  width: 20px;
  height: 20px;
}

.explanation {
  margin-top: 4px;
  font-size: 0.9rem;
}

.text {
  margin-left: 5px;
}
</style>
