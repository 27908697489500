<template>
  <div
    class="lunch__week"
    :class="{
      'lunch__week--active': isShowingRows,
      'lunch__week--done': isCreated,
      'lunch-week-vacation': onVacation
    }"
  >
    <div class="lunch__top" @click.stop="setIsShowingRows">
      <div class="lunch__title">{{ heading }}</div>
      <div class="lunch__date">
        {{ dates.startDate | dateFormat }} - {{ endDate | dateFormat }}
      </div>
    </div>
    <div class="lunch__rows">
      <lunch-day-row
        v-for="(date, index) in dates.weekDates"
        :key="index"
        :date="dates.weekDates[index].date"
        :dayAmount="weekAmount[dates.weekDates[index].weekday]"
        :inOffice="inOffice[dates.weekDates[index].weekday]"
        v-model.number="weekAmount[dates.weekDates[index].weekday]"
        @inOffice="inOffice[dates.weekDates[index].weekday] = $event"
        @showModal="setSelectedWeekday"
      />
    </div>
    <lunch-modal
      v-show="isShowingModal"
      v-model="weekNotes[selectedWeekday]"
      :date="selectedWeekDate"
      @hideModal="isShowingModal = false"
    />
    <transition name="fade">
      <feedback v-if="showSaveSuccess" />
    </transition>
  </div>
</template>

<script>
import { createLunchRecord, getLunchWeek, saveLunchRecord } from "@/api";
import Feedback from "@/components/Feedback";
import LunchDayRow from "./LunchDayRow";
import LunchModal from "./LunchModal";
import format from "date-fns/format";
import getISOWeek from "date-fns/getISOWeek";

export default {
  name: "LunchWeek",
  components: {
    Feedback,
    LunchModal,
    LunchDayRow
  },

  props: {
    dates: {
      type: Object,
      required: true
    },
    currentUserOffice: {
      type: String,
      required: true
    },
    onVacation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedWeekDate: null,
      isShowingRows: false,
      isShowingModal: false,
      weekAmount: {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: ""
      },
      weekNotes: {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: ""
      },
      inOffice: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false
      },
      isCreated: false, // If a record for this week is found in firebase this is set to yes.,
      showSaveSuccess: false
    };
  },

  computed: {
    selectedWeekday() {
      return this.selectedWeekDate
        ? format(this.selectedWeekDate, "EEEE")
        : "Monday";
    },

    isCurrentWeek() {
      return this.dates.weekNumber === getISOWeek(new Date());
    },

    heading() {
      return this.isCurrentWeek ? "This Week" : `Week ${this.dates.weekNumber}`;
    },

    endDate() {
      return this.dates.weekDates[4].date;
    }
  },

  filters: {
    dateFormat(value) {
      return format(value, "dd/MM");
    }
  },

  created() {
    this.handleGetLunchWeek();
  },

  methods: {
    validateForm() {
      let isValid = true;
      let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

      weekDays.forEach(day => {
        if (this.weekAmount[day] === "") {
          this.weekAmount[day] = 0;
        }
      });
      return isValid;
    },

    setIsShowingRows() {
      if (!this.onVacation) {
        this.isShowingRows = !this.isShowingRows;
      }
    },

    setSelectedWeekday(date) {
      this.selectedWeekDate = date;
      this.isShowingModal = true;
    },

    handleGetLunchWeek() {
      return new Promise(res => {
        let user = this.currentUserEmail;
        let year = this.dates.year;
        getLunchWeek({ user, week: this.dates.weekNumber, year }, data => {
          this.isCreated = true;
          this.$emit("isCreated");
          this.weekNotes = {
            ...data.weekNotes
          };
          this.weekAmount = {
            ...data.weekAmount
          };
          this.inOffice = {
            ...data.inOffice
          };
          res();
        });
      });
    },

    async handleSave() {

      try {
        const weekHasBeenEdited = Object.values(this.weekAmount).some(
          e => e !== ""
        );

        // If the week has beed edited then we save it
        if (weekHasBeenEdited && this.validateForm()) {

        const userEmail = this.currentUserEmail;
          const newLunchRecord = createLunchRecord({
            office: this.currentUserOffice,
            user: userEmail,
            weekNumber: this.dates.weekNumber,
            year: this.dates.year,
            weekAmount: this.weekAmount,
            weekNotes: this.weekNotes,
            inOffice: this.inOffice
          });
          await saveLunchRecord(newLunchRecord);
          await this.handleGetLunchWeek();
        }

      } catch (error) {
        /* eslint-disable no-console */
        console.error("Could not save lunch week", error)
      /* eslint-enable no-console */
      }

    }
  }
};
</script>

<style>
.lunch__week {
    margin: 4px 0 0 0;
    background-color: #ececec;
}

.lunch__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.lunch__top {
    position: relative;
    padding: 16px 16px 8px 16px;
    cursor: pointer;
}

.lunch__top:hover,
.lunch__week--active .lunch__top {
    border-color: #dbdbdb;
}

.lunch__top::before {
    content: "\e903";
    position: absolute;
    top: 15px;
    right: 16px;
    z-index: 2;
    font-family: "icons";
    font-size: 20px;
    line-height: 1;
    color: #e68b8b;
    transition: all 0.1s ease-in-out;
    font-weight: 900;
}

.lunch__week--done .lunch__top::before {
    content: "\e904";
    top: 18px;
    right: 19px;
    color: #4fa75a;
    font-size: 12px;
    font-weight: 300;
}

.lunch__day > span {
    color: #aba8a8;
}

.lunch__title {
    font-family: "Graphik";
    font-size: 14px;
    font-weight: 700;
}

.lunch__date {
    padding-right: 36px;
    font-size: 16px;
    color: #999;
}

.lunch__rows {
    overflow: hidden;
    height: 0;
    background-color: #fff;
    border: 4px solid #ececec;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
    transition: all 0.2s ease-in-out;
}

.lunch__week--active .lunch__rows {
    height: auto;
    padding: 0 16px 16px 16px;
    opacity: 1;
    transform: scaleY(1);
}

.lunch-week-vacation {
    opacity: 0.2;
    pointer-events: none;
}
</style>
