<template>
  <div>
    <form class="doing">
      <div class="form__box">
        <div class="vacation">
          <lunch-vacation-box
            :onVacation="onVacation"
            @setOnVacation="handleSetOnVacation($event)"
          >I'm on vacation/sick leave. (This will persist until you
            uncheck).
          </lunch-vacation-box
          >
        </div>
        <div v-if="!onVacation">
          <div class="form__label">My tasks</div>
          <div class="form__field icon--edit">
            <textarea
              class="form__input form__input--tasks"
              name="tasks"
              style="height: 99px;"
              v-model="taskRecord.workingOn"
              @click="setSelectedDaysInput('')"
              @input="debounceTextInput"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form__two" v-if="!onVacation">
        <div class="form__box" @click="setSelectedDaysInput('billable')">
          <div class="form__label">Billable days</div>
          <div class="form__field icon--crosshair form__field--days">
            <input
              class="form__input"
              type="tel"
              name="billable"
              maxlength="3"
              disabled
              v-model.number="taskRecord.billableDays"
            />
          </div>
        </div>
        <div class="form__box" @click="setSelectedDaysInput('available')">
          <div class="form__label">Available days</div>
          <div class="form__field icon--clock form__field--days">
            <input
              class="form__input"
              type="tel"
              name="available"
              maxlength="3"
              disabled
              v-model.number="taskRecord.availableDays"
            />
          </div>
        </div>
      </div>
      <div class="form__box" v-if="!onVacation">
        <ul class="days" :class="{ 'days--active': showDays }">
          <li
            v-for="(item, index) in daysList"
            :key="index"
            class="days__item"
            @click="setInput(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="form__box">
        <div class="form__label">I need help with</div>
        <div class="form__field icon--edit">
            <textarea
              class="form__input form__input--tasks"
              name="help"
              style="height: 99px;"
              v-model="taskRecord.needHelpWith"
              @click="setSelectedDaysInput('')"
              @input="debounceTextInput"
            ></textarea>
        </div>
      </div>

      <div class="form__box" v-if="!onVacation">
        <button
          class="form__submit button-photo"
          @click.prevent="$emit('setShowPhoto', true)"
        >
          Add Photo
        </button>
      </div>
      <div class="form__box button-container">
        <p
          v-show="
            saveState === SAVESTATES.SAVING || saveState === SAVESTATES.SAVED
          "
          class="saving-message"
        >
          {{ saveState === SAVESTATES.SAVING ? "Saving..." : "Saved" }}
        </p>
        <p
          v-show="saveState === SAVESTATES.ERROR"
          class="saving-message saving-error"
        >
          Couldn't save. Something's wrong.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import {
  getAllVacations,
  getTaskRecord,
  saveTaskRecord,
  setUserVacationStatus
} from "@/api";
import LunchVacationBox from "@/components/Tasks/LunchVacationBox";

import { debounce } from "debounce";

export default {
  props: {
    currentUserOffice: {
      type: String,
      required: true
    }
  },
  components: {
    LunchVacationBox
  },

  data() {
    return {
      SAVESTATES: {
        READY: "READY",
        SAVING: "SAVING",
        SAVED: "SAVED",
        ERROR: "ERROR"
      },
      // Listeners that will need to be unsubscribed to
      unsubscribes: [],
      saveState: null,
      errorMsg: "",
      queuedSave: false,
      daysList: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      selectedDaysInput: "",
      onVacation: false,
      taskRecord: {
        billableDays: 0,
        availableDays: 0,
        workingOn: "",
        needHelpWith: "",
        user: ""
      }
    };
  },

  async created() {
    const vacations = await getAllVacations();
    const entity = vacations.find(e => e.email === this.currentUserEmail);
    if (!entity) {
      this.onVacation = false;
    } else {
      this.onVacation = entity.onVacation;
    }

    this.debouncedTextInput = debounce(() => {
      this.handleSaveTaskRecord();
    }, 250);
  },

  async mounted() {
    this.saveState = this.SAVESTATES.READY;
    // Get stored task record from firebase (if any exists)
    await this.handleGetTaskRecord();
  },

  computed: {
    showDays() {
      return this.selectedDaysInput !== "";
    }
  },

  methods: {
    async handleSetOnVacation(e) {
      await setUserVacationStatus({
        email: this.currentUserEmail,
        onVacation: e.target.checked
      });
      this.onVacation = e.target.checked;
    },

    async handleGetTaskRecord() {
      const docId = this.currentWeekRecordId(this.currentUserEmail);

      let response = await getTaskRecord(docId);
      if (response !== null) {
        this.taskRecord = {
          ...response
        };
      }

      this.taskRecord.user = this.currentUserEmail;
    },

    handleSaveTaskRecord() {
      this.enqueueTaskRecordSave();
    },

    async enqueueTaskRecordSave() {
      if (this.saveState === this.SAVESTATES.SAVING) {
        this.queuedSave = true;
        return;
      }

      this.saveState = this.SAVESTATES.SAVING;
      this.queuedSave = false;

      const docId = this.currentWeekRecordId(this.currentUserEmail);

      let task = {
        ...this.taskRecord,
        week: this.weekNumber,
        year: new Date().getFullYear(),
        office: this.currentUserOffice
      };

      try {
        await saveTaskRecord(docId, task);
        this.saveState = this.SAVESTATES.SAVED;

        setTimeout(() => {
          this.saveState = this.SAVESTATES.READY;
        }, 3000);
      } catch (err) {
        this.errorMsg = `Couldn't save: ${err}`;
        this.saveState = this.SAVESTATES.ERROR;

        setTimeout(() => {
          this.saveState = this.SAVESTATES.ready;
          this.taskRecord.workingOn = "";
          this.errorMsg = "";
        });
      } finally {
        if (this.queuedSave) {
          this.handleSaveTaskRecord();
        }
      }
    },

    debounceTextInput(e) {
      this.debouncedTextInput(e);
    },

    debouncedTextInput() {
    }, // This is set during the

    setInput(value) {
      if (this.selectedDaysInput === "available") {
        this.taskRecord.availableDays = value;
      } else {
        this.taskRecord.billableDays = value;
      }
      this.selectedDaysInput = "";
      this.enqueueTaskRecordSave();
    },

    setSelectedDaysInput(value) {
      this.selectedDaysInput = value;
    }
  },
  beforeDestroy() {
    this.unsubscribes.forEach(unsubscribe => unsubscribe());
  }
};
</script>

<style>
.form__box {
    position: relative;
    margin: 0 0 20px 0;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.form__label {
    margin: 0 0 8px 0;
    font-family: "Graphik", serif;
    font-size: 14px;
    font-weight: 700;
}

.form__field {
    position: relative;
}

.form__field::before {
    position: absolute;
    top: 23px;
    left: 20px;
    z-index: 2;
    font-family: "icons";
    font-size: 22px;
    line-height: 1;
    color: #dbdbdb;
}

.form__input,
.form__submit {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 20px 16px 20px 50px;
    border: 4px solid #dbdbdb;
    border-radius: 0;
    background-color: transparent;
    line-height: 1;
    font-size: 18px;
    color: #424242;
}

.form__input:disabled {
    color: #424242;
    -webkit-text-fill-color: #424242;
    opacity: 1;
}

.form__input:focus {
    border-color: #26c6da;
}

.form__two {
    display: flex;
    flex-flow: row nowrap;
}

.form__two .form__box:first-child {
    padding-right: 10px;
}

.form__two .form__box:last-child {
    padding-left: 10px;
}

.form__box:last-child {
    margin: 0;
}

.days {
    list-style: none;
    display: grid;
    height: 0;
    overflow: hidden;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    transition: all 0.2s ease-out;
}

.days--active {
    height: 190px;
}

.days__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border: 4px solid #dbdbdb;
    background-color: #dbdbdb;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.days__item:hover {
    border-color: #26c6da;
    background-color: #fff;
}

.form__submit {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    border-color: #26c6da;
    background-color: #26c6da;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: "Graphik", serif;
    font-weight: 700;
}

.form__submit:hover {
    background-color: #fff;
    color: #26c6da;
}

.button-photo {
    background: #fafafa;
    color: #333;
    width: 100%;
}

.saving-message {
    position: fixed;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 4px 8px;
    background-color: #2f9caa;
    color: #fff;
    font-family: "Graphik", serif;
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    transition: 3s ease-in-out 2s;
}

.form__error {
    margin: 16px 0;
    padding: 8px;
    color: #fff;
    font-family: "Graphik", serif;
    font-size: 14px;
}

.saving-error {
    background-color: tomato;
    transition: none;
}

.vacation {
    margin-bottom: 1rem;
}

@media screen and (min-width: 800px) {
    .saving-message {
        font-size: 16px;
    }
}
</style>
