<template>
  <div>Signed out</div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "@/firebase.js";

export default {
  mounted() {
    const auth = getAuth(firebaseApp);
    signOut(auth).then(() => {
      // Sign-out successful.
      document.location.replace("https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0");
    }).catch((error) => {
      /* eslint-disable no-console */
      console.log(error)
    });
  }
};
</script>

<style></style>
