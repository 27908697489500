<template>
  <ul class="app__menu">
    <li
      class="app__menu-item"
      :class="{ 'app__menu-item--active': !isShowingLunch }"
      @click="() => $emit('setShowingLunch', false)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        class="app__menu-icon app__menu-icon--tasks"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <g>
          <path
            d="M19 5h-2V3h-1.142c-.447-1.719-2-3-3.858-3S8.589 1.281 8.142 3H7v2H5c-1.103 0-2 .898-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V7c0-1.102-.897-2-2-2zm-7-3c.739 0 1.385.402 1.731 1h-3.463c.347-.598.993-1 1.732-1zM9 5h6v2H9V5zm10 16H5V7h2v2h10V7h2v14z"
          ></path>
        </g>
      </svg>
    </li>
    <li
      class="app__menu-item"
      :class="{ 'app__menu-item--active': isShowingLunch }"
      @click="() => $emit('setShowingLunch', true)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        class="app__menu-icon app__menu-icon--lunch"
      >
        <path fill="none" d="M-.001 0H24v24H-.001z"></path>
        <g>
          <path
            d="M15.707 2.293A.998.998 0 0 0 14 3v19h2v-6h2a1 1 0 0 0 1-1c0-9.306-3.158-12.572-3.293-12.707zM16 14V7.243c.487 1.6.908 3.808.986 6.757H16zM10 8c0 .737-.405 1.375-1 1.723V2H7v7.723C6.405 9.375 6 8.737 6 8V2H4v6c0 1.858 1.279 3.411 3 3.858V22h2V11.858c1.721-.448 3-2 3-3.858V2h-2v6z"
          ></path>
        </g>
      </svg>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["isShowingLunch"]
};
</script>

<style>
.app__menu {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #26c6da;
  box-shadow: 0 -8px 16px rgba(25, 124, 136, 0.1);
}
@media screen and (min-width: 800px) {
  .app__menu {
    position: static;
    margin: 0 0 30px 0;
  }
}

.app__menu-item {
  position: relative;
  display: block;
  width: 100%;
  height: 56px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.app__menu-item:last-child {
  margin: 0 0 0 4px;
}
.app__menu-item::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 50%;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  transform: translateX(-50%) scale(0);
  transition: all 0.2s ease-in-out;
}
.app__menu-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  fill: #fff;
  opacity: 0.8;
  transform: translate(-50%, -50%) scale(0.7);
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 800px) {
  .app__menu-item:hover .app__menu-icon {
    fill: #000;
  }
}
.app__menu-item--active .app__menu-icon {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.app__menu-item--active::after {
  transform: translateX(-50%) scale(1);
}
</style>
