import addDays from "date-fns/addDays";
import { format, isWeekend, isFriday } from "date-fns";
import getISOWeek from "date-fns/getISOWeek";
import { getAuth } from "firebase/auth";

import { parseEmail } from "@/js/helpers";
import firebaseApp from "@/firebase";

export default {
  computed: {
    // Get the current week number at this current moment
    // If the date is on a weekend, we add 1 week number to the current week number
    weekNumber() {
      const now = new Date();
      let weekNumber = getISOWeek(now);

      // If we are on a friday or on the weekend
      // the user probably wants to add their tasks for the next week
      if (isFriday(now) || isWeekend(now)) {
        weekNumber = weekNumber + 1;
      }

      return weekNumber;
    },

    weekDay() {
      return new Date().getDay();
    },

    currentUserEmail() {
      const auth = getAuth(firebaseApp);

      if (auth.currentUser !== null) {
        return parseEmail(auth.currentUser.email);
      } else {
        return "";
      }
    }
  },
  methods: {
    currentWeekRecordId(email) {
      return `${email.trim()}-${new Date().getFullYear()}-${this.weekNumber}`;
    },

    historicRecordId({ week, year, email }) {
      return `${email.trim()}-${year}-${week}`;
    },

    createWeek(startDate) {
      let weekDates = [];
      let weekNumber = getISOWeek(startDate);
      let year = addDays(startDate, 4).getFullYear();
      for (let i = 0; i < 5; i++) {
        let date = addDays(startDate, i);
        let obj = {
          date,
          weekday: format(date, "EEEE")
        };
        weekDates.push(obj);
      }
      return {
        startDate,
        weekDates,
        weekNumber,
        year
      };
    }
  }
};
