<template>
  <div>
    <div class="app__welcome">{{ welcomeText }}</div>
    <div v-if="imageExists" class="image-container">
      <img :src="image != null ? image : imageUrlWithConstraints" alt="taskImage" width="100%" />
    </div>
    <image-uploader
      v-if="!imageExists"
      :debug="0"
      :quality="0.9"
      :preview="false"
      :autoRotate="true"
      outputFormat="file"
      :className="['fileinput', { 'fileinput--loaded': hasImage }]"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="setImage"
    ></image-uploader>
    <div class="form__box button-container">
      <button class="button-back" @click="$emit('setShowPhoto', false)">
        Back To Task
      </button>
      <button v-if="imageExists" class="button-back button-delete" @click="deleteImage">
        Delete Image
      </button>
    </div>
    <div class="form__box">
      <p :class="saving ? 'saving-message' : 'saved-message'">{{ saving ? "Saving..." : "Saved" }}</p>
    </div>
  </div>
</template>

<script>
import { saveTaskRecord, subscribeTaskRecord } from "@/api";

export default {
  data() {
    return {
      saving: false,
      image: null,
      hasImage: false,
      taskRecord: null,
      downloadUrl: null,
      cloudinary: {
        cloudName: "eggs-design",
        presetID: "ic8ajd6h"
      }
    };
  },

  computed: {
    welcomeText() {
      if (this.imageExists) return "This is your photo:";
      else return "Add a photo to spice up your monday standup!";
    },

    imageExists() {
      return this.taskRecord && this.taskRecord.imageUrl;
    },

    taskRecordId() {
      return this.currentWeekRecordId(this.currentUserEmail);
    },

    imageUrlWithConstraints() {
      if (this.imageExists) {
        let splitUrl = this.taskRecord.imageUrl.split("/upload");
        return `${splitUrl[0]}/upload/q_80,w_800/${splitUrl[1]}`;
      } else {
        return "";
      }
    }
  },

  methods: {
    async deleteImage() {
      try {
        if (this.saving) {
          return;
        }
        this.saving = true;
        await saveTaskRecord(this.taskRecordId, { imageUrl: "" });
        this.saving = false;
      } catch (err) {
        throw new Error(err);
      }
    },

    async setImage(output) {
      if (this.saving) {
        return;
      }

      // Upload image to cloudinary
      const url = `https://api.cloudinary.com/v1_1/${this.cloudinary.cloudName}/upload`;
      const formData = new FormData();
      formData.append("file", output);
      formData.append("upload_preset", this.cloudinary.presetID);

      this.saving = true;

      try {
        let response = await fetch(url, {
          method: "POST",
          body: formData
        });

        let data = await response.json();
        if (response.ok) {
          await saveTaskRecord(this.taskRecordId, {
            imageUrl: data.secure_url
          });
        }
      } catch (error) {
        throw new Error(error);
      }

      this.image = URL.createObjectURL(output);
      this.saving = false;
    }
  },

  created() {
    subscribeTaskRecord(this.taskRecordId, record => {
      if (record) {
        this.taskRecord = record;
      }
    });
  }
};
</script>

<style scoped>
.image-container {
    max-width: 100%;
}

.button-back {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    border: 4px solid #26c6da;
    background-color: #26c6da;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: "Graphik";
    font-weight: 700;
    padding: 20px 16px 20px 20px;
    margin-top: 40px;
    line-height: 1;
    font-size: 18px;
}

.button-back:hover {
    background-color: #fff;
    color: #26c6da;
}

.button-delete {
    background: #fff;
    color: #727272;
}

.saved-message,
.saving-message {
    position: fixed;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 4px 8px;
    background-color: #2f9caa;
    color: #fff;
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    transition: 3s ease-in-out 2s;
    opacity: 0;
}

.saving-message {
    transition: none;
    opacity: 0.8;
}

@media screen and (min-width: 800px) {
    .saved-message,
    .saving-message {
        font-size: 16px;
    }
}
</style>
