export const parseEmail = email => {
  switch (email) {

    /* TRONDHEIM OFFICE */
    case "carl@eggsdesign.no":
      email = "carl.norstebo@eggsdesign.com";
      break;

    case "carl-gustaf.lundholm@eggsdesign.no":
      email = "cg.lundholm@eggsdesign.com";
      break;

    /* OSLO OFFICE */
    case "marte@eggsdesign.no":
      email = "marte.grevsgard@eggsdesign.com";
      break;

    case "oyvind.valmot@eggsdesign.com":
      email = "oyvind.lium.valmot@eggsdesign.com";
      break;

      case "cathrine.sandvold@eggsdesign.com":
      email = "cathrine.solem@eggsdesign.com";
      break;

    default:
      break;
  }

  if (email.endsWith(".no")) {
    email = email.substring(0, email.length - 3) + ".com";
  }
  return email;
};
