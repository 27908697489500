<template>
  <div class="wrap">
    <tasks-lunch-header
      @setShowingLunch="handleSetShowingLunch"
      :isShowingLunch="isShowingLunch"
    />
    <keep-alive>
      <transition name="fade" mode="out-in">
        <tasks-edit
          v-if="!isShowingLunch"
          :name="name"
          :currentUserOffice="currentUserOffice"
          key="tasks"
        />
        <lunch-edit
          v-else
          :name="name"
          :currentUserOffice="currentUserOffice"
          key="lunch"
        />
      </transition>
    </keep-alive>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import { getUserOffice } from "@/api";

import LunchEdit from "../components/Tasks/LunchEdit";
import TasksLunchHeader from "../components/Tasks/TasksLunchHeader";
import TasksEdit from "../components/Tasks/TasksEdit";

import firebaseApp from "@/firebase.js";

export default {
  data() {
    return {
      isShowingLunch: false,
      currentUserOffice: ""
    };
  },

  computed: {
    name() {
      // Names are stored with " // EGGS " so we split out that part
      const auth = getAuth(firebaseApp)
      return auth.currentUser.displayName.split("/")[0].trim();
    }
  },

  components: {
    TasksLunchHeader,
    TasksEdit,
    LunchEdit
  },

  methods: {
    handleSetShowingLunch(bool) {
      this.isShowingLunch = bool;
    },

    async getCurrentUserOffice() {
      let user = this.currentUserEmail;
      const result = await getUserOffice(user);
      this.currentUserOffice = result[0].offices[0].office;

      // Oslo doesn't use tasks-module, so set lunch as default view for them.
      if (result[0].offices[0].office === "oslo") {
        this.isShowingLunch = true;
      }
    }
  },

  created() {
    this.getCurrentUserOffice();
  }
};
</script>

<style>
.wrap {
  margin: 0 auto 20px auto;
  padding: 0 20px 56px 20px;
  max-width: 480px;
  font-family: "MetaSerif", sans-serif;
  font-size: 18px;
  color: #212121;
}
</style>
