<template>
  <div class="app__lunch lunch state--hidden">
    <h2 class="form__header">I want lunch!</h2>
    <div class="app__welcome">
      Of course,
      <span class="app__user">{{ name }}</span
      >! But we need to know if you need lunch four weeks in advance to properly
      prepare.<br />
      Bon Appétit!<br /><br />
      <strong>Note:</strong> The lunch plan for a given day is locked at 12PM
      the day before.
    </div>
    <div v-if="!currentUserOffice || currentUserOffice === ''">
      <p style="color: red;margin-bottom: 24px;">Wait! We are missing information about which office you belong to. Ask
        your Culture and People manager to make sure you have an office set in "Sanity".</p>
    </div>
    <lunch-vacation-box
      :onVacation="onVacation"
      @setOnVacation="handleSetOnVacation($event)"
    >
      I'm on vacation/sick leave. (This will persist until you uncheck).
    </lunch-vacation-box>
    <lunch-status
      v-if="!onVacation"
      :numberOfWeeksUpdated="numberOfWeeksUpdated"
    />
    <div class="lunch">
      <lunch-week
        :currentUserOffice="currentUserOffice"
        :dates="currentWeek"
        :onVacation="onVacation"
        @showModal="isShowingModal = true"
        @isCreated="checkWeek"
      />
      <lunch-week
        :currentUserOffice="currentUserOffice"
        :dates="week2"
        :onVacation="onVacation"
        @showModal="isShowingModal = true"
        @isCreated="checkWeek"
      />
      <lunch-week
        :currentUserOffice="currentUserOffice"
        :dates="week3"
        :onVacation="onVacation"
        @showModal="isShowingModal = true"
        @isCreated="checkWeek"
      />
      <lunch-week
        :currentUserOffice="currentUserOffice"
        :dates="week4"
        :onVacation="onVacation"
        @showModal="isShowingModal = true"
        @isCreated="checkWeek"
      />
    </div>
    <div class="form__error saving-error" v-if="error">
      {{ error }}
    </div>
    <div class="form__box">
      <button v-if="!onVacation" class="form__submit" @click="handleSave">
        {{ saveButtonText }}
      </button>
    </div>
    <portal-target name="lunch-modal"></portal-target>
  </div>
</template>

<script>
import addWeeks from "date-fns/addWeeks";
import startOfWeek from "date-fns/startOfWeek";
import LunchStatus from "@/components/Tasks/LunchStatus";
import LunchVacationBox from "@/components/Tasks/LunchVacationBox";
import LunchWeek from "./LunchWeek";
import { setCurrentUserInfo, subscribeCurrentUserInfo } from "@/api";


export default {
  components: {
    LunchStatus,
    LunchVacationBox,
    LunchWeek
  },

  props: ["name", "currentUserOffice"],
  data() {
    return {
      isShowingModal: false,
      modalInfo: {},
      numberOfWeeksUpdated: 0, // Child component LunchWeek emits event that increases this. If it gets to 4, we show "well done"-message. if not, show "you need to fill out"
      onVacation: false,
      unsubscribes: [], // Listeners that will need to be unsubscribed to
      saving: false,
      error: false // Potential error message when saving lunch
    };
  },
  methods: {
    async handleSave() {
      this.saving = true;

      let lunchWeeks = this.$children.map(comp => {
        if (comp.$options._componentTag === "lunch-week") return comp;
      });

      let allWeeksPromises = [];
      lunchWeeks.forEach(comp => {
        if (comp) {
          let promise = comp.handleSave();
          allWeeksPromises.push(promise);
        }
      });

      try {
        await Promise.all(allWeeksPromises);
        this.error = false;
      } catch (error) {
        // @note This is a temporary solution to the problem of the error message, so we can test it with any user that encounters this error.
        /* eslint-disable no-console */
        console.error(error);
        this.error = error?.message || "Something went wrong when saving lunch, check with your Culture and People manager to see if they can fill out the lunch plan.";
      } finally {
        this.saving = false;
      }

    },
    // Checks if user has updated all weeks.
    checkWeek() {
      this.numberOfWeeksUpdated = 0;
      let weeks = this.$children.filter(
        child => child.$options._componentTag === "lunch-week"
      );
      weeks.forEach(week => {
        if (week.isCreated) this.numberOfWeeksUpdated += 1;
      });
    },

    handleSetOnVacation(e) {
      setCurrentUserInfo({ prop: "onVacation", value: e.target.checked });
    }
  },
  computed: {
    saveButtonText() {
      if (!this.saving) {
        return "Save lunch list";
      } else {
        return "Saving";
      }
    },

    currentWeek() {
      let startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
      return this.createWeek(startDate);
    },
    week2() {
      let startDate = addWeeks(this.currentWeek.startDate, 1);
      return this.createWeek(startDate);
    },
    week3() {
      let startDate = addWeeks(this.currentWeek.startDate, 2);
      return this.createWeek(startDate);
    },
    week4() {
      let startDate = addWeeks(this.currentWeek.startDate, 3);
      return this.createWeek(startDate);
    }
  },

  created() {
    let unsubscribe = subscribeCurrentUserInfo(data => {
      if (data) {
        this.onVacation = data.onVacation;
      }
    });
    this.unsubscribes.push(unsubscribe);
  },

  beforeDestroy() {
    this.unsubscribes.forEach(unsubscribe => unsubscribe());
  }
};
</script>
