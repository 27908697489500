import { initializeApp } from "firebase/app"

const config = {
  apiKey: "AIzaSyDPn56SOPGit4aqLdZr95OUItYiaupTYfM",
  // authDomain: "eggs-frying-pan.firebaseapp.com",
  authDomain: "fryingpan.eggsdesign.com",
  databaseURL: "https://eggs-frying-pan.firebaseio.com",
  projectId: "eggs-frying-pan",
  storageBucket: "eggs-frying-pan.appspot.com",
  messagingSenderId: "430950751777"
};


const firebaseApp = initializeApp(config);

export default firebaseApp;
