import Router from "vue-router";
import { getAuth, onAuthStateChanged, signInWithRedirect, OAuthProvider } from "firebase/auth";
import Vue from "vue";

import SignedOut from "./views/SignedOut.vue";
import TasksAndLunch from "./views/TasksAndLunch.vue";
import firebaseApp from "./firebase";
import store from "./store";

Vue.use(Router);


const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // This is where you edit your tasks and lunch

    {
      path: "/",
      name: "Tasks And Lunch",
      component: TasksAndLunch,
      meta: {
        auth: true
      }
    },
    {
      path: "/standup/:office",
      name: "Standup",
      component: () => import("./views/StandupView.vue"),
      meta: {
        auth: true
      }
    },
    {
      path: "/standup/:office/admin",
      name: "Admin",
      component: () => import("./views/StandupAdmin"),
      meta: {
        auth: true
      }
    },
    // View for seeing an office's tasks overview
    {
      path: "/screen/:office",
      name: "Screen",
      component: () => import("./views/Screen.vue"),
      meta: {
        auth: true
      },
      children: [
        {
          path: ":year/:week",
          component: () => import("./views/Screen.vue"),
          meta: {
            auth: true
          }
        }
      ]
    },
    // View for choosing selected person in the screen view
    {
      path: "/picker/:office",
      name: "Picker",
      component: () => import("./views/Picker.vue")
    },
    // View for seeing an office's lunch view
    {
      path: "/lunch/:office",
      name: "Lunch Overview",
      component: () => import("./views/Lunch.vue"),
      meta: {
        auth: true
      }
    },
    // This route is used for Azure AD single sign out.
    // Whenever a user signs out from AD, this endpoint is hit
    // and we perform the signOut() method
    {
      path: "/signedout",
      name: "SignedOut",
      component: SignedOut
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    await store.dispatch("setLoading", true);
    let user = await getCurrentUser();
    await store.dispatch("setLoading", false);

    if (user) {
      next();
    } else {
      const auth = getAuth(firebaseApp);
      const provider = new OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        tenant: "c7e2b1b4-7370-4c50-8816-5487707f73c6"
      });
      await signInWithRedirect(auth, provider);
    }
  } else {
    next();
  }
});

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export default router;
