<template>
  <div id="app">
    <router-view v-if="!loading" />
    <loading v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/Loading";
export default {
  components: {
    Loading
  },

  computed: {
    ...mapState(["loading"])
  }
};
</script>
