import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  doc,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { parseEmail } from "@/js/helpers";
import sanity from "./sanity";
import firebaseApp from "@/firebase";

const db = getFirestore(firebaseApp);

// SANITY.IO
export const fetchPeople = async (office) => {
  const query = `
  *[
    _type == "employee" && 
    type == "employee" && 
    !(title match "Chef") &&
    offices[0]->.slug.current == $office
  ]{ firstName, lastName, email }`;
  const response = await sanity.fetch(query, { office });
  return response.sort((a, b) => {
    if (a.firstName < b.firstName) return -1;
    else return 1;
  });
};

export const getUserOffice = async email => {
  // Because some early employees have email addresses ending with .no in Azure, but ending in .com in sanity we
  // only check whats before the @.
  let user = email.split("@")[0];

  return new Promise(res => {
    //const query = `*[_type == "employee"] {'cast': offices[0]._ref->office}[10]`;
    const query = `*[_type == 'employee' && email match "${user}"]
      {
        offices[]->{'office': slug.current}
    }`;
    sanity.fetch(query).then(people => {
      res(people);
    });
  });
};

// FIREBASE

function getAuthenticatedUserEmail() {
  const auth = getAuth(firebaseApp);
  return parseEmail(auth.currentUser.email);
}

// Subscribe to firebase User info
export const subscribeCurrentUserInfo = cb => {
  const email = getAuthenticatedUserEmail();
  const userRef = doc(db, "users", email);
  return onSnapshot(userRef, (doc) => {
    cb(doc.data());
  });
};

export const setCurrentUserInfo = ({ prop, value }) => {
  const email = getAuthenticatedUserEmail();
  let obj = {};
  obj[prop] = value;
  const userRef = doc(db, "users", email);
  return updateDoc(userRef, obj);
};

/* Screen view: Set a highlighted person */
export const setAsSelectedPerson = (email, office) => {
  const docId = `board-selected-${office}`;
  const selectedBoardRef = doc(db, "settings", docId);
  return setDoc(selectedBoardRef, { email });
};

/* Screen view: Set mode: Board/Panic/Gecko */
export const setSelectedMode = (mode, office) => {
  const docId = `mode-selected-${office}`;
  const selectedModeRef = doc(db, "settings", docId);
  return setDoc(selectedModeRef, { mode });
};

export const setRandomizedEmployee = (employee, office) => {
  const docId = `board-randomized-${office}`;
  const selectedRandomizedEmployee = doc(db, "settings", docId);
  return setDoc(selectedRandomizedEmployee, { employee });
};

/* Screen: Subscribe to board mode updates */
export const subscribeToMode = (office, cb) => {
  const docId = `mode-selected-${office}`;
  const modeRef = doc(db, "settings", docId);
  return onSnapshot(modeRef, (doc) => {
    if (doc.data()) {
      cb(doc.data().mode);
    }
  });
};

export const subscribeToRandomizedEmployee = (office, cb) => {
  const docId = `board-randomized-${office}`;
  const employeeRef = doc(db, "settings", docId);
  return onSnapshot(employeeRef, (doc) => {
    if (doc.data()) {
      cb(doc.data());
    }
  });
};

/* Screen: Subscribe to updates on employee  */
export const subscribeSelectedPerson = (office, cb) => {
  let docId = `board-selected-${office}`;
  const selectedPersonRef = doc(db, "settings", docId);
  return onSnapshot(selectedPersonRef, (doc) => {
    if (doc.data()) {
      cb(doc.data().email);
    }
  });
};

export const getLunchWeek = async ({ user, week, year }, cb) => {
  try {
    const docId = `${user}-${year}-${week}`;
    const docRef = doc(db, "lunch", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      cb(docSnap.data());
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const createLunchRecord = ({
                                    office,
                                    user,
                                    weekNumber,
                                    year,
                                    weekAmount,
                                    weekNotes,
                                    inOffice
                                  }) => {
  let record = {
    office,
    user,
    weekAmount: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0
    },
    weekNotes: {
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: ""
    },
    inOffice: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false
    },
    weekNumber,
    year,
    id: `${user}-${year}-${weekNumber}`
  };
  if (weekAmount) {
    record.weekAmount = {
      ...weekAmount
    };
  }
  if (weekNotes) {
    record.weekNotes = {
      ...weekNotes
    };

    if (inOffice) {
      record.inOffice = {
        ...inOffice
      };
    }
  }
  return record;
};

export const saveLunchRecord = (lunchRecord) => {
  const ref = doc(db, "lunch", lunchRecord.id);
  return setDoc(ref, lunchRecord, { merge: true });
};

export const getLunchRecords = async ({ office, week }) => {
  const lunchRef = collection(db, "lunch");
  const q = query(
    lunchRef,
    where("office", "==", office),
    where("weekNumber", "==", week.weekNumber),
    where("year", "==", week.year)
  );

  const querySnapshot = await getDocs(q);
  let docs = [];
  querySnapshot.forEach((doc) => {
    let record = doc.data();
    record.id = doc.id;
    docs.push(record);
  });
  return docs;
};

export const subscribeLunchRecords = ({ office, week, year }, cb) => {
  const lunchRef = collection(db, "lunch");
  const q = query(
    lunchRef,
    where("office", "==", office),
    where("weekNumber", "==", week),
    where("year", "==", year)
  );

  return onSnapshot(q, (querySnapshot) => {
    let records = [];
    querySnapshot.forEach(doc => {
      records.push(doc.data());
    });
    cb(records);
  });
};

export const subscribeTaskRecords = ({ office, week, year }, cb) => {
  const taskRecordsRef = collection(db, "taskRecords");
  const q = query(
    taskRecordsRef,
    where("office", "==", office),
    where("week", "==", week),
    where("year", "==", year)
  );
  return onSnapshot(q, querySnapshot => {
    let records = [];
    querySnapshot.forEach(doc => {
      records.push(doc.data());
    });
    cb(records);
  });
};

export const getTaskRecord = async (docId) => {
  const taskRecordRef = doc(db, "taskRecords", docId);
  const docSnap = await getDoc(taskRecordRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const saveTaskRecord = (docId, taskRecord) => {
  const taskRecordRef = doc(db, "taskRecords", docId);
  return setDoc(taskRecordRef, taskRecord, { merge: true });
};

export const subscribeTaskRecord = (docId, cb) => {
  const taskRecordRef = doc(db, "taskRecords", docId);
  return onSnapshot(taskRecordRef, (doc) => {
    if (doc.data()) {
      cb(doc.data());
    } else {
      cb(null);
    }
  });
};

export const subscribeAggregatedRecord = (
  { type, office, year, weekNumber },
  cb
) => {
  const docId = `${type}-${office}-${year}-${weekNumber}`;
  const aggregatedDataRef = collection(db, "aggregatedData", docId);
  return onSnapshot(aggregatedDataRef, (doc) => {
    if (doc.data()) {
      cb(doc.data());
    }
  });
};

export const getSettingsDoc = async ({ docId }) => {
  const settingsDocRef = doc(db, "settings", docId);
  const document = await getDoc(settingsDocRef);
  return document.data();
};


export const getAllVacations = async () => {
  const vacationRef = collection(db, "vacation");
  const querySnapshot = await getDocs(vacationRef);
  let docs = [];
  querySnapshot.forEach(doc => {
    let record = doc.data();
    record.id = doc.id;
    docs.push(record);
  });
  return docs;
};

export const setUserVacationStatus = async ({ email, onVacation }) => {
  let obj = { email, onVacation };
  const userVacationRef = doc(db, "vacation", email);
  return setDoc(userVacationRef, obj, { merge: true });
};
