<template>
  <div class="app__tasks tasks">
    <h2 class="form__header">My tasks for the week</h2>
    <div class="app__welcome" v-if="!showPhotoView">
      Welcome,
      <span class="app__user">{{ name }}</span
      >! Please tell us what you're doing this week.
    </div>
    <keep-alive>
      <tasks-edit-form
        v-if="!showPhotoView"
        :currentUserOffice="currentUserOffice"
        @setShowPhoto="showPhotoView = $event"
      />
      <tasks-edit-photo v-else @setShowPhoto="showPhotoView = $event" />
    </keep-alive>
  </div>
</template>

<script>
import TasksEditForm from "@/components/Tasks/TasksEditForm";
import TasksEditPhoto from "@/components/Tasks/TasksEditPhoto";

export default {
  components: {
    TasksEditForm,
    TasksEditPhoto
  },

  props: {
    name: {
      type: String,
      required: true
    },
    currentUserOffice: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showPhotoView: false
    };
  }
};
</script>

<style>
.form__header {
  margin: 40px 0 30px 0;
  font-family: "Graphik";
  font-size: 22px;
  font-weight: 700;
}

.app__welcome {
  margin-bottom: 30px;
  line-height: 1.4;
}

.app__user {
  text-transform: capitalize;
}

.app__empty {
  text-decoration: underline;
  color: #26c6da;
  cursor: pointer;
}
</style>
